export const optionParent = {
  height: 'auto',
  calcHeight: 95,
  tip: false,
  searchShow: true,
  searchMenuSpan: 24,
  border: true,
  index: true,
  selection: true,
  viewBtn: true,
  menuWidth: 150,
  dialogWidth: 880,
  dialogClickModal: false,
  searchMenuPosition: "right",
  labelPosition: 'top',
  column: [
    {
      label: "名称",
      prop: "name",
      search: true,
      searchSpan: 12,
      span: 12,
      rules: [
        {
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }
      ]
    },
    {
      label: "编码",
      prop: "code",
      search: true,
      searchSpan: 12,
      align: "center",
      rules: [
        {
          required: true,
          message: "请输入编码",
          trigger: "blur"
        }
      ]
    },
    {
      label: "字典排序",
      prop: "sort",
      type: "number",
      align: "right",
      width: 80,
      hide: true,
      rules: [
        {
          required: true,
          message: "请输入字典排序",
          trigger: "blur"
        }
      ]
    },
    {
      label: "描述",
      prop: "tag",
      hide: true,
      span: 24,
      type: "textarea",
    }
  ]
};

export const optionChild = {
  height: 'auto',
  calcHeight: 95,
  tip: false,
  searchShow: true,
  searchMenuSpan: 24,
  searchMenuPosition: "right",
  labelPosition: 'top',
  tree: true,
  border: true,
  index: true,
  selection: true,
  viewBtn: true,
  menuWidth: 150,
  dialogWidth: 880,
  dialogClickModal: false,
  column: [
    {
      label: "名称",
      prop: "name",
      search: true,
      searchSpan: 12,
      span: 12,
      rules: [
        {
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }
      ]
    },
    {
      label: "编码",
      prop: "code",
      search: true,
      searchSpan: 12,
      align: "center",
      rules: [
        {
          required: true,
          message: "请输入编码",
          trigger: "blur"
        }
      ]
    },
    {
      label: "上级字典",
      prop: "parent_id",
      type: "select",
      dicData: [],
      hide: true,
      props: {
        label: "name",
        value:'id'
      },
      addDisabled: true,
      editDisabled: true,
      rules: [
        {
          required: false,
          message: "请选择上级字典",
          trigger: "click"
        }
      ]
    },
    {
      label: "字典排序",
      prop: "sort",
      type: "number",
      align: "right",
      width: 80,
      hide: true,
      rules: [
        {
          required: true,
          message: "请输入字典排序",
          trigger: "blur"
        }
      ]
    },
    {
      label: "描述",
      prop: "tag",
      span: 24,
      type: "textarea",
      hide: true
    }
  ]
};
